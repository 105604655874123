<template>
  <p></p>
</template>

<script>
import authenticationController from '@/authenticationController'

export default {
  name: 'ExternalLoginUserConnected',
  async created () {
    const accessToken = this.$route.query.access_token
    const provider = this.$route.query.provider
    const outsider = this.$route.query.outsider === 'true'

    await this.$http().authenticateFromToken(accessToken)
    authenticationController.storeProvider(provider)
    authenticationController.setOutsider(outsider)

    import('@/recentsController').then(r => r.default.loadRecents())
      .finally(() => {
        this.$router.replace({
          path: this.$router.currentRoute.query.redirect || '/'
        })
      })
  }
}
</script>
